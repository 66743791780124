<template>
  <b-card
    class="text-center"
  >
    <div class="card-wrap">
      <h2 class="number">
        请输入预报单号
      </h2>
      <div class="number-box">
        <sub-code
          ref="subCode"
          @codeSix="handleVerifyPreshipment"
        />
      </div>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        size="lg"
        @click="handleVerifyPreshipment"
      >
        {{ isLoading ? 'Loading...' : '确定' }}
      </b-button>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SubCode from './components/code.vue'

export default {
  components: {
    SubCode,
    BCard,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isLoading: false,
    }
  },
  mounted() {
    document.onkeydown = () => {
      const key = window.event.keyCode
      if (key === 13) {
        this.handleVerifyPreshipment()
      }
    }
  },
  methods: {
    async handleVerifyPreshipment() {
      try {
        const subCode = this.$refs.subCode.code
        this.isLoading = true
        const data = await this.$api.verifyPreshipment({ id: subCode })
        this.isLoading = false
        if (data.code === 200) {
          this.handleSubmit()
        }
      } catch (error) {
        this.isLoading = false
        console.log('error', error)
      }
    },
    async handleSubmit() {
      try {
        const subCode = this.$refs.subCode.code
        if (subCode.length === 6) {
          this.isLoading = true
          const data = await this.$api.predictionInfo({ id: subCode })
          this.isLoading = false
          if (data.code === 200) {
            this.$router.push({ name: 'agentsubmit', params: { id: subCode } })
          } else {
            this.$bvToast.toast(data.data.info, {
              title: '提示',
              variant: 'danger',
              solid: true,
              toaster: 'b-toaster-top-center',
            })
          }
        } else {
          this.$bvModal
            .msgBoxConfirm('请输入六位预报单号', {
              cancelVariant: 'outline-danger',
              centered: true,
              buttonSize: 'sm',
            })
        }
      } catch (error) {
        this.isLoading = false
        console.log('error', error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.number{
  font-size: 30px;
}
.card-wrap{
  padding: 140px 0;
}
.number-box{
  margin: 160px;
}
</style>
